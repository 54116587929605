// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --login-bg: white;
}

.LoginScreen_root__zPsg7 {
    justify-content: center;
    background-color: var(--login-bg);
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
    margin-right: 15px;
    min-height: 100px;
}

.LoginScreen_content__3OjKy {
    /* display: flex; */
    max-width: 1200px;
    padding: 0 10px;
    margin: 0 auto;
}

.LoginScreen_connectButton__R1EYg {
    width: 100%;
    justify-content: center;
    margin: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/login/LoginScreen.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;IACvB,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[":root {\n    --login-bg: white;\n}\n\n.root {\n    justify-content: center;\n    background-color: var(--login-bg);\n    border-radius: 10px;\n    padding: 16px;\n    display: flex;\n    flex-direction: column;\n    height: fit-content;\n    margin-right: 15px;\n    min-height: 100px;\n}\n\n.content {\n    /* display: flex; */\n    max-width: 1200px;\n    padding: 0 10px;\n    margin: 0 auto;\n}\n\n.connectButton {\n    width: 100%;\n    justify-content: center;\n    margin: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `LoginScreen_root__zPsg7`,
	"content": `LoginScreen_content__3OjKy`,
	"connectButton": `LoginScreen_connectButton__R1EYg`
};
export default ___CSS_LOADER_EXPORT___;
