// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PostMedia_cover__r3ant {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 30px;
  max-height: 550px;
}

.PostMedia_image__WNp5N {
  width: 100%;
  height: 100%;
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.PostMedia_slider__fNPvR img {
  max-height: 500px;
  object-fit: scale-down;
  object-position: center;
  max-width: 100%;
  flex: 1 1;
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.PostMedia_slider__fNPvR a {
  display: flex;
}

.PostMedia_dots__Yef-V {
  margin-bottom: 0;
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.PostMedia_dots__Yef-V li {
  box-shadow: 0 0 6px 1px rgb(0 0 0 / 75%);
}
`, "",{"version":3,"sources":["webpack://./src/content/PostMedia.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA,gEAAgE;AAChE;EACE,iBAAiB;EACjB,sBAAsB;EACtB,uBAAuB;EACvB,eAAe;EACf,SAAO;AACT;;AAEA,gEAAgE;AAChE;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA,gEAAgE;AAChE;EACE,wCAAwC;AAC1C","sourcesContent":[".cover {\n  background-color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n  margin-bottom: 30px;\n  max-height: 550px;\n}\n\n.image {\n  width: 100%;\n  height: 100%;\n}\n\n/* stylelint-disable-next-line selector-max-compound-selectors */\n.slider img {\n  max-height: 500px;\n  object-fit: scale-down;\n  object-position: center;\n  max-width: 100%;\n  flex: 1;\n}\n\n/* stylelint-disable-next-line selector-max-compound-selectors */\n.slider a {\n  display: flex;\n}\n\n.dots {\n  margin-bottom: 0;\n}\n\n/* stylelint-disable-next-line selector-max-compound-selectors */\n.dots li {\n  box-shadow: 0 0 6px 1px rgb(0 0 0 / 75%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cover": `PostMedia_cover__r3ant`,
	"image": `PostMedia_image__WNp5N`,
	"slider": `PostMedia_slider__fNPvR`,
	"dots": `PostMedia_dots__Yef-V`
};
export default ___CSS_LOADER_EXPORT___;
