// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphChangeButton_root__ai68A {
  margin: 0 6px;
}
`, "",{"version":3,"sources":["webpack://./src/network/GraphChangeButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf","sourcesContent":[".root {\n  margin: 0 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `GraphChangeButton_root__ai68A`
};
export default ___CSS_LOADER_EXPORT___;
