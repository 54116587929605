import { toMultibaseHash } from "./hash.js";
/**
 * createNote() provides a simple factory for generating an ActivityContentNote
 * object.
 * @param content - The text content to include in the note
 * @param published - the Date that the note was claimed to be published
 * @param options - Overrides default fields for the ActivityContentNote
 * @returns An ActivityContentNote object
 */
export const createNote = (content, published, options) => ({
    "@context": "https://www.w3.org/ns/activitystreams",
    type: "Note",
    mediaType: "text/plain",
    published: published.toISOString(),
    content,
    ...options,
});
/**
 * createProfile() provides a simple factory for generating an
 * ActivityContentProfile object.
 * @param options - Overrides any default fields for the ActivityContentProfile
 * @returns An ActivityContentProfile object
 */
export const createProfile = (options) => ({
    "@context": "https://www.w3.org/ns/activitystreams",
    type: "Profile",
    ...options,
});
/**
 * createAudioAttachment() provides a simple factory for generating an
 * ActivityContentAudio object.
 * @param links - An array of ActivityContentAudioLink objects to include
 * @param options - Overrides any default fields for the ActivityContentAudio
 * @returns An ActivityContentAudio object
 */
export const createAudioAttachment = (links, options) => ({
    type: "Audio",
    url: links,
    ...options,
});
/**
 * createAudioLink() provides a simple factory for generation an
 * ActivityContentAudioLink object for inclusion in an ActivityContentAudio
 * object.
 * @param href      - The URL of the file
 * @param mediaType - The MIME type of the file (see SUPPORTED_AUDIO_MEDIA_TYPES within validation.ts)
 * @param hash      - An ActivityContentHash object to authenticate the file
 * @param options - Overrides any default fields for the ActivityContentAudioLink
 * @returns An ActivityContentAudioLink object
 */
export const createAudioLink = (href, mediaType, hash, options) => ({
    type: "Link",
    href,
    mediaType,
    hash,
    ...options,
});
/**
 * createImageAttachment() provides a simple factory for generating an
 * ActivityContentImage object.
 * @param links - An array of ActivityContentImageLink objects to include
 * @param options - Overrides any default fields for the ActivityContentImage
 * @returns An ActivityContentImage object
 */
export const createImageAttachment = (links, options) => ({
    type: "Image",
    url: links,
    ...options,
});
/**
 * createImageLink() provides a simple factory for generation an
 * ActivityContentImageLink object for inclusion in an ActivityContentImage
 * object.
 * @param href      - The URL of the file
 * @param mediaType - The MIME type of the file (see SUPPORTED_IMAGE_MEDIA_TYPES within validation.ts)
 * @param hash      - An ActivityContentHash object to authenticate the file
 * @param options - Overrides any default fields for the ActivityContentImageLink
 * @returns An ActivityContentImageLink object
 */
export const createImageLink = (href, mediaType, hash, options) => ({
    type: "Link",
    href,
    mediaType,
    hash,
    ...options,
});
/**
 * createVideoAttachment() provides a simple factory for generating an
 * ActivityContentVideo object.
 * @param links - An array of ActivityContentVideoLink objects to include
 * @param options - Overrides any default fields for the ActivityContentVideo
 * @returns An ActivityContentVideo object
 */
export const createVideoAttachment = (links, options) => ({
    type: "Video",
    url: links,
    ...options,
});
/**
 * createVideoLink() provides a simple factory for generation an
 * ActivityContentVideoLink object for inclusion in an ActivityContentVideo
 * object.
 * @param href      - The URL of the file
 * @param mediaType - The MIME type of the file (see SUPPORTED_VIDEO_MEDIA_TYPES within validation.ts)
 * @param hash      - An ActivityContentHash [multihash](https://github.com/multiformats/multihash) to authenticate the file
 * @param options - Overrides any default fields for the ActivityContentVideoLink
 * @returns An ActivityContentVideoLink object
 */
export const createVideoLink = (href, mediaType, hash, options) => ({
    type: "Link",
    href,
    mediaType,
    hash,
    ...options,
});
/**
 * createLinkAttachment() provides a simple factory for generating an
 * ActivityContentLink object.
 * @param href - The URL to include in the link
 * @param options - Overrides any default fields for the ActivityContentLink
 * @returns An ActivityContentLink object
 */
export const createLinkAttachment = (href, options) => ({
    type: "Link",
    href,
    ...options,
});
/**
 * createLocation() provides a simple factory for generating an
 * ActivityContentLocation object.
 * @param name - This is the string provided for name of the Location
 * @param options - Overrides any default fields for the ActivityContentLocation
 * @returns An ActivityContentLocation object
 */
export const createLocation = (name, options) => ({
    type: "Place",
    name,
    ...options,
});
/**
 * createHashtag() provides a simple factory for generating an
 * ActivityContentHashtag object.
 * @param name - The hashtag value, without "#" character
 * @returns An ActivityContentTag object
 */
export const createHashtag = (name) => ({
    name,
});
/**
 * createMention() provides a simple factory for generating an
 * ActivityContentMention object.
 * @param id - The DSNPUserId of the mention user
 * @param options - Any additional fields for the ActivityContentMention
 * @returns An ActivityContentMention object
 */
export const createMention = (id, options) => ({
    type: "Mention",
    id,
    ...options,
});
export const createInteraction = (href, rel, nonce, ticket, options) => ({
    type: "Interaction",
    href,
    rel,
    nonce,
    ticket,
    ...options,
});
/**
 * createHash() provides a simple factory for generating an ActivityContentHash
 * compliant multihash. This factory assumes the user intends to use a standard
 * blake2b-256 hash. To use other authentication algorithms, users should build
 * their own multihash.
 * @param content - The file content to be hashed
 * @returns An ActivityContentHash [multihash](https://github.com/multiformats/multihash) containing the blake2b-256 hash of the content
 */
export const createHash = (content) => {
    return toMultibaseHash(content);
};
