// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --feed-bg: white;
    --feed-fg: #111;
    --nav-fg: gray;
    --nav-spacer: #f37a21;
}

.Feed_root__BCLU9 {
    background-color: var(--feed-bg);
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    height: -moz-fit-content;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .Feed_header__RoMxb {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--feed-fg);
    padding-bottom: 12px;
  }

  .Feed_backArrow__whAMM {
    cursor: pointer;
    background-color: transparent;
    border-radius: 5px;
    padding: 0 2px;
    transition: background-color 0.3s;

    &:hover {
      background-color: var(--nav-fg);
    }
  }

  .Feed_navigation__uZDLv {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .Feed_navigationItem__5IekP {
    padding: 0 10px;
    cursor: pointer;
  }

  .Feed_navigationItem--active__QrDTZ {
    text-decoration: underline;
  }

  .Feed_navigationSpacer__wJN3F {
    width: 5px;
    height: 22px;
    transform: skew(-20deg);
    background: var(--nav-spacer);
    margin: 0 6px;
  }
`, "",{"version":3,"sources":["webpack://./src/Feed.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,gCAAgC;IAChC,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,wBAAwB;IACxB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,uCAAuC;IACvC,oBAAoB;EACtB;;EAEA;IACE,eAAe;IACf,6BAA6B;IAC7B,kBAAkB;IAClB,cAAc;IACd,iCAAiC;;IAEjC;MACE,+BAA+B;IACjC;EACF;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,6BAA6B;IAC7B,aAAa;EACf","sourcesContent":[":root {\n    --feed-bg: white;\n    --feed-fg: #111;\n    --nav-fg: gray;\n    --nav-spacer: #f37a21;\n}\n\n.root {\n    background-color: var(--feed-bg);\n    border-radius: 10px;\n    padding: 16px;\n    display: flex;\n    flex-direction: column;\n    height: fit-content;\n    height: -moz-fit-content;\n    margin-right: 15px;\n    margin-bottom: 15px;\n  }\n\n  .header {\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 2px solid var(--feed-fg);\n    padding-bottom: 12px;\n  }\n\n  .backArrow {\n    cursor: pointer;\n    background-color: transparent;\n    border-radius: 5px;\n    padding: 0 2px;\n    transition: background-color 0.3s;\n\n    &:hover {\n      background-color: var(--nav-fg);\n    }\n  }\n\n  .navigation {\n    display: flex;\n    align-items: center;\n    font-weight: bold;\n  }\n\n  .navigationItem {\n    padding: 0 10px;\n    cursor: pointer;\n  }\n\n  .navigationItem--active {\n    text-decoration: underline;\n  }\n\n  .navigationSpacer {\n    width: 5px;\n    height: 22px;\n    transform: skew(-20deg);\n    background: var(--nav-spacer);\n    margin: 0 6px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Feed_root__BCLU9`,
	"header": `Feed_header__RoMxb`,
	"backArrow": `Feed_backArrow__whAMM`,
	"navigation": `Feed_navigation__uZDLv`,
	"navigationItem": `Feed_navigationItem__5IekP`,
	"navigationItem--active": `Feed_navigationItem--active__QrDTZ`,
	"navigationSpacer": `Feed_navigationSpacer__wJN3F`
};
export default ___CSS_LOADER_EXPORT___;
