// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_root__40Hjl {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: transparent;
}

.App_content__wwONY {
  padding: 10px;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".root {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  background-color: transparent;\n}\n\n.content {\n  padding: 10px;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `App_root__40Hjl`,
	"content": `App_content__wwONY`
};
export default ___CSS_LOADER_EXPORT___;
