// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConnectionsListProfiles_profile__FyF87 {
  display: flex;
  flex: 1 1;
  align-items: center;
}

.ConnectionsListProfiles_name__au8sy {
  display: flex;
  flex: 1 1;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/network/ConnectionsListProfiles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,eAAe;AACjB","sourcesContent":[".profile {\n  display: flex;\n  flex: 1;\n  align-items: center;\n}\n\n.name {\n  display: flex;\n  flex: 1;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile": `ConnectionsListProfiles_profile__FyF87`,
	"name": `ConnectionsListProfiles_name__au8sy`
};
export default ___CSS_LOADER_EXPORT___;
