export const parentURLs = [
  "https://experience-guide-dev.becknprotocol.io",
  "https://experience-guide-staging.becknprotocol.io",
  "https://experience-guide.becknprotocol.io",
  "https://experience.becknprotocol.io",
  "http://localhost:3001",
  "https://experience-dev.becknprotocol.io",
  "https://experience-staging.becknprotocol.io",
  "https://experience-prod.becknprotocol.io",
];
