// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --header-bg: white;
}

.Header_root__39Vua {
  background-color: var(--header-bg);
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.Header_title__Wb\\+7S {
  margin: 0;
  font-style: italic;
}

.Header_user__98zxx {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/chrome/Header.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;EAClC,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[":root {\n  --header-bg: white;\n}\n\n.root {\n  background-color: var(--header-bg);\n  padding: 0 16px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 16px;\n}\n\n.title {\n  margin: 0;\n  font-style: italic;\n}\n\n.user {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Header_root__39Vua`,
	"title": `Header_title__Wb+7S`,
	"user": `Header_user__98zxx`
};
export default ___CSS_LOADER_EXPORT___;
