// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --profile-bg: white;
}

.HeaderProfile_root__HlxJI {
  background-color: var(--profile-bg);
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  margin-right: 15px;
  margin-bottom: 15px;
}

.HeaderProfile_profile__KMUnI {
  margin: 15px 0;
}

.HeaderProfile_metaInnerBlock__0ABLG {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/chrome/HeaderProfile.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,wBAAmB;EAAnB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[":root {\n  --profile-bg: white;\n}\n\n.root {\n  background-color: var(--profile-bg);\n  border-radius: 10px;\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  height: fit-content;\n  margin-right: 15px;\n  margin-bottom: 15px;\n}\n\n.profile {\n  margin: 15px 0;\n}\n\n.metaInnerBlock {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `HeaderProfile_root__HlxJI`,
	"profile": `HeaderProfile_profile__KMUnI`,
	"metaInnerBlock": `HeaderProfile_metaInnerBlock__0ABLG`
};
export default ___CSS_LOADER_EXPORT___;
