// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --hash-bg: white;
  --border: black;
  --border-hover: #999;
}

.PostHashDropdown_root__3fZLQ {
  display: block;
}

.PostHashDropdown_replyRoot__GLWMT {
  position: absolute;
  top: 4px;
  right: 4px;
}

.PostHashDropdown_menu__9ne\\+8 {
  max-width: 200px;
  overflow-wrap: break-word;
  white-space: pre-wrap;

  /* background-color: var(--hash-bg); */
  padding: 12px;
}

.PostHashDropdown_title__ijheA {
  font-weight: bold;
  line-height: 1;
}

.PostHashDropdown_button__VgWUs {
  background-color: var(--hash-bg);
  border: 2px solid var(--border);
  border-radius: 5px;
  cursor: pointer;
  font-size: 11px;
  transition: 0.25s linear all;
}

.PostHashDropdown_button__VgWUs:hover {
  border-color: var(--border-hover);
}
`, "",{"version":3,"sources":["webpack://./src/content/PostHashDropdown.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,qBAAqB;;EAErB,sCAAsC;EACtC,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,gCAAgC;EAChC,+BAA+B;EAC/B,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[":root {\n  --hash-bg: white;\n  --border: black;\n  --border-hover: #999;\n}\n\n.root {\n  display: block;\n}\n\n.replyRoot {\n  position: absolute;\n  top: 4px;\n  right: 4px;\n}\n\n.menu {\n  max-width: 200px;\n  overflow-wrap: break-word;\n  white-space: pre-wrap;\n\n  /* background-color: var(--hash-bg); */\n  padding: 12px;\n}\n\n.title {\n  font-weight: bold;\n  line-height: 1;\n}\n\n.button {\n  background-color: var(--hash-bg);\n  border: 2px solid var(--border);\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 11px;\n  transition: 0.25s linear all;\n}\n\n.button:hover {\n  border-color: var(--border-hover);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PostHashDropdown_root__3fZLQ`,
	"replyRoot": `PostHashDropdown_replyRoot__GLWMT`,
	"menu": `PostHashDropdown_menu__9ne+8`,
	"title": `PostHashDropdown_title__ijheA`,
	"button": `PostHashDropdown_button__VgWUs`
};
export default ___CSS_LOADER_EXPORT___;
