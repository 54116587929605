// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReplyInput_root__fT2kc {
  border-bottom: 1px solid #999;
  padding: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/content/ReplyInput.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".root {\n  border-bottom: 1px solid #999;\n  padding: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ReplyInput_root__fT2kc`
};
export default ___CSS_LOADER_EXPORT___;
