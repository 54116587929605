// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Post_root__7yp3i {
  max-width: 1000px;
}

.Post_metaBlock__OazBR {
  cursor: pointer;
}

.Post_metaInnerBlock__O6kqq {
  display: flex;
  align-items: center;
}

.Post_caption__MgiR9 {
  margin: 16px 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  white-space: pre-line;
}

.Post_time__69FIT {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  top: 24px;
  right: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/content/Post.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,yBAAyB;EACzB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb","sourcesContent":[".root {\n  max-width: 1000px;\n}\n\n.metaBlock {\n  cursor: pointer;\n}\n\n.metaInnerBlock {\n  display: flex;\n  align-items: center;\n}\n\n.caption {\n  margin: 16px 0;\n  word-wrap: break-word;\n  overflow-wrap: break-word;\n  hyphens: auto;\n  white-space: pre-line;\n}\n\n.time {\n  display: flex;\n  align-items: flex-end;\n  flex-direction: column;\n  position: absolute;\n  top: 24px;\n  right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Post_root__7yp3i`,
	"metaBlock": `Post_metaBlock__OazBR`,
	"metaInnerBlock": `Post_metaInnerBlock__O6kqq`,
	"caption": `Post_caption__MgiR9`,
	"time": `Post_time__69FIT`
};
export default ___CSS_LOADER_EXPORT___;
