// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConnectionsList_root__F0ewF {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  margin-right: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/network/ConnectionsList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,wBAAmB;EAAnB,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".root {\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  height: fit-content;\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ConnectionsList_root__F0ewF`
};
export default ___CSS_LOADER_EXPORT___;
